/// <reference path='../typings/ajax-loader.d.ts'/>

namespace AAS {
    export class AjaxLoaderRequest implements IAjaxLoaderRequest {

        private readonly localUrlPrefix: string = '/AlternativeArticles';
        private readonly stargateUrlPrefix: string = '/alternative-articles';
        private readonly staticParameters: string;
        private readonly isLocalEnv: boolean;
        private readonly baseUrl: string;

        constructor(
            readonly isMobileShop: boolean,
            readonly maxNumberOfAlternativeArticles: string = '3'
        ) {
            this.isLocalEnv = document.querySelector('html').classList.contains('local');
            this.baseUrl = this.isLocalEnv ? this.localUrlPrefix : this.stargateUrlPrefix;

            this.staticParameters =
                'viewMode=ajax' +
                `&maxNumberOfAlternativeArticles=${this.maxNumberOfAlternativeArticles}` +
                `&isMobileShop=${this.isMobileShop}`;
        }

        requestFor(savKeyToLoad: string, content: string, culture: string, itemOrigin: string, detailsCanBeHidden: boolean, displayedInCategoryNavKey: string, showHeader: boolean): Promise<string> {
            return new Promise<string>(resolve => {
                const url = this.getAjaxUrl(savKeyToLoad, content, culture, itemOrigin, detailsCanBeHidden, displayedInCategoryNavKey, showHeader);
                const request = new XMLHttpRequest();

                request.onreadystatechange = () => {
                    if (request.readyState === 4 && request.status === 200) {
                        resolve(request.responseText);
                    } else if (request.status >= 300 || request.status === 204) {
                        resolve('');
                    }
                };

                request.ontimeout = () => {
                    resolve('');
                };
                
                request.open('GET', url, true);
                request.timeout = 30000;
                request.send();
            });
        }

        private getAjaxUrl(savKey: string, content: string, culture: string, itemOrigin: string, detailsCanBeHidden: boolean, displayedInCategoryNavKey: string, showHeader: boolean): string {
            const culturePrefix: string = !this.isLocalEnv ? '/' + culture.split('-')[0] : '';
            const itemOriginParameter: string = itemOrigin != null ? `&itemorigin=${itemOrigin}` : '';
            const detailsCanBeHiddenParameter = `&detailsCanBeHidden=${detailsCanBeHidden}`;
            const showHeaderParameter = `&showHeader=${showHeader}`;
            const displayedInCategoryNavKeyParameter: string = displayedInCategoryNavKey ? `&displayedInCategoryNavKey=${displayedInCategoryNavKey}` : '';

            return `${culturePrefix}${this.baseUrl}/articles/${savKey}?${this.staticParameters}&content=${content}${itemOriginParameter}` +
                `${detailsCanBeHiddenParameter}${displayedInCategoryNavKeyParameter}${showHeaderParameter}`;
        }
    }
}

